import React from "react"
import * as LayoutModule from '../../styles/layout.module.css'

export function Col({colspan, children}) {
  let className = LayoutModule[`col${colspan}`];
  return (
    <div className={className}>
      {children}
    </div>
  )
}
