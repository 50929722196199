import React from "react"
import * as WaveStyle from "../styles/wave.module.css"

export function Wave(props) {
  const bgColor = props.backgroundColor
  const waveUseVariant = getSvgUseForVariant(props)

  return (
    <div style={{ backgroundColor: bgColor }}>
      <svg className={WaveStyle.waves} xmlns="http://www.w3.org/2000/svg"
           viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
        <defs>
          <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"/>
        </defs>
        <g className={WaveStyle.parallax}>
          {waveUseVariant}
        </g>
      </svg>
    </div>
  )
}

function getSvgUseForVariant(props) {
  const waveColor = props.waveColor;
  let waveVariantUse = <use xlinkHref="#gentle-wave" x="48" y="0" fill={waveColor}/>

  if (props['variant'] && Number.isInteger(props['variant'])) {
    const variant = props['variant'];
    switch (variant) {
      case 1:
        waveVariantUse = <use className={WaveStyle.useVariant1} xlinkHref="#gentle-wave" x="48" y="3" fill={waveColor}/>
        break
      case 2:
        waveVariantUse = <use className={WaveStyle.useVariant2} xlinkHref="#gentle-wave" x="48" y="5" fill={waveColor}/>
        break
      case 3:
        waveVariantUse = <use className={WaveStyle.useVariant3} xlinkHref="#gentle-wave" x="48" y="7" fill={waveColor}/>
        break
      default:
        console.error(`There is no wave variant ${variant}`);
    }
  }

  return waveVariantUse
}
