import React from "react"
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet"

export default class LocationMap extends React.Component {

    render() {
        const {position, zoom, markerPosition, popupText} = this.props.locationMap;

        if (typeof window !== "undefined") {
            return (
                <MapContainer id='DerWegWiesAbgeht' center={position} zoom={zoom} scrollWheelZoom={false}
                              style={{height: '300px'}}>
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                               attribution={"Map data &copy; <a href=\"https://www.openstreetmap.org/\">OpenStreetMap</a> contributors"}/>
                    <Marker position={markerPosition}>
                        <Popup><b>{popupText}</b></Popup>
                    </Marker>
                </MapContainer>
            );
        }
        return null;
    }
}
