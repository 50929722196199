import React from 'react';

import * as NewsModule from '../styles/news.module.css';
export default function News(props) {
    let links = props.links

    function renderNewsLinks(links) {
        return links.map((node, index) => {
            return (
                <a href={node.url} key={`newsLink${index}`} target="_blank" rel="noopener noreferrer">{node.title}</a>
            )
        })
    }

    return (
        <div className={NewsModule.news}>
            {renderNewsLinks(links)}
        </div>
    );
}
