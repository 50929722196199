import React from "react"
import {ContentRow} from "../layout/ContentRow"

import * as LayoutModule from '../../styles/layout.module.css';
import * as TextModule from '../../styles/text.module.css'

import WaveIcon from '../../assets/svg/ocean-waves.svg';
import {graphql, useStaticQuery} from "gatsby";
import {extractFirstNodeProperty} from "../../util/QueryExtractor";

export default function IntroductionRow() {
    const {homepage} = useStaticQuery(graphql`
        query IntroductionQuery {
            homepage: allStrapiHomepage(filter: {publishedAt: {ne: null}}) {
                nodes {
                    introductionSubTitle
                    introductionTags
                    introductionTitle
                }
            }
        }
    `);

    const introductionTitle = extractFirstNodeProperty(homepage, 'introductionTitle');
    const introductionSubTitle = extractFirstNodeProperty(homepage, 'introductionSubTitle');
    const introductionTags = extractFirstNodeProperty(homepage, 'introductionTags').split('\n');

    const textBlockClassNames = `${TextModule.tag} ${TextModule.tagBlock} mt-1`
    const introductionTagBlocks = introductionTags.map((textBlock, index) =>
        <div key={`introductionTagBlock${index}`}>
            <span className={textBlockClassNames}>{textBlock}</span><br/>
        </div>
    )

    return (
        <ContentRow>
            <h1>{ introductionTitle }</h1>
            <div className={LayoutModule.flexContainerCenter}>
                <WaveIcon width={'3rem'}/>
                <h2>{ introductionSubTitle }</h2>
                <WaveIcon width={'3rem'}/>
            </div>

            <div style={{marginTop: '1rem'}}>
                {introductionTagBlocks}
            </div>
        </ContentRow>
    );
}
