import React from "react"
import {ContentRow} from "../layout/ContentRow"
import {graphql, useStaticQuery} from "gatsby";
import {extractFirstNodeProperty} from "../../util/QueryExtractor";
import {Gallery} from "../gallery/Gallery";

const GalleryRow = () => {
    const {gallery} = useStaticQuery(graphql`
            query GalleryQuery {
                gallery: allStrapiGallery {
                    nodes {
                        title
                        images {
                            caption
                            url
                        }
                    }
                }
            }
    `);

    let title = extractFirstNodeProperty(gallery, 'title');
    let images = extractFirstNodeProperty(gallery, 'images');

    return (
        <ContentRow>
            <h2>{title}</h2>
            <Gallery images={images} />
        </ContentRow>
    )
}
export default GalleryRow
