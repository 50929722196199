import React from 'react';

import * as FooterModule from '../styles/footer.module.css';
import { Link } from "gatsby"

export default function Footer() {
  return (
    <footer className={FooterModule.footer}>
      <div className={FooterModule.footerNote}>
        <p>
          Steimers Strandbar<br/>
          Schoolpad Strand<br/>
          26571 Juist
        </p>
        <p>
          <Link to='/impressum/'>Impressum</Link><br/>
          <Link to='/datenschutz/'>Datenschutzerklärung</Link>
        </p>
      </div>
    </footer>
  );
}
