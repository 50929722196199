import React from "react"

export default function Background(props) {
  return (
    <div style={{
      width: '100%',
      backgroundColor: props.backgroundColor
    }}>
      {props.children}
    </div>
  )
}
