import React from "react"
import ImageGallery from "react-image-gallery";

export function Gallery({images}) {

    function prefixUploadUrl(imageUrl) {
        return `${process.env.GATSBY_GALLERY_UPLOADS_URL}${imageUrl}`
    }

    function mapImagesToItems(images) {
        return images.map((image) => {
            return {
                original: prefixUploadUrl(image.url),
                description: image.caption
            }
        });
    }

    const items = mapImagesToItems(images);

    return (
        <ImageGallery items={items} showThumbnails={false} lazyLoad={true}/>
    );
}
