import React from "react"
import * as LayoutStyle from '../../styles/layout.module.css'

export function Container({children}) {
  return (
    <div className={LayoutStyle.row}>
      {children}
    </div>
  )
}
