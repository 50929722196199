import React from 'react'
import * as LayoutStyle from '../../styles/layout.module.css';

export function ContentRow({ children }) {
  return (
    <div className={LayoutStyle.contentRow}>
      {children}
    </div>
  )
}
