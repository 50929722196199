import React from 'react'
import * as LayoutStyle from '../../styles/layout.module.css';

export default function Content(props) {
  return (
    <div className={LayoutStyle.contentContainer}>
      { props.children }
    </div>
  );
}
