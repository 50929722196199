// extracted by mini-css-extract-plugin
export var black = "weather-module--black--5vITR";
export var blue = "weather-module--blue--p8Kk-";
export var cyan = "weather-module--cyan--wVc3H";
export var glitch = "weather-module--glitch--3O9Xs";
export var glitchSkew = "weather-module--glitch-skew--k1QVc";
export var gray = "weather-module--gray--2R835";
export var green = "weather-module--green--HbZt-";
export var magenta = "weather-module--magenta--gfP57";
export var movingLines = "weather-module--moving-lines--EHYWI";
export var red = "weather-module--red--PuesS";
export var stripeOverlay = "weather-module--stripe-overlay--VJsl6";
export var testColorBlock = "weather-module--test-color-block--UWMUe";
export var testColorBlockSmall = "weather-module--test-color-block-small--2xsY+";
export var testPicture = "weather-module--test-picture--mLPYn";
export var testPictureRow = "weather-module--test-picture-row--ADMoR";
export var testText = "weather-module--test-text--IYhjT";
export var yellow = "weather-module--yellow--7MNyt";