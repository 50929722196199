import React from "react"
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {ContentRow} from "../layout/ContentRow"
import {Container} from "../layout/Container"
import * as TextModule from "../../styles/text.module.css"
import * as DrinkModule from "../../styles/drinks.module.css"
import {Col} from "../layout/Col"
import {graphql, useStaticQuery} from "gatsby";
import {extractFirstNodeProperty, extractSingleNodeObject} from "../../util/QueryExtractor";

export default function DrinksRow() {
    const {drink, images} = useStaticQuery(graphql`
        query DrinkQuery {
            drink: allStrapiDrink(filter: {publishedAt: {ne: null}}) {
                   nodes {
                        CocktailDescription
                        CoffeeDescription
                        SoftdrinksDescription
                        Title
                    }
            }
            images: allFile(filter: {relativeDirectory: {eq: "drinks"}}) {
                nodes {
                    name
                    childImageSharp {
                        gatsbyImageData(placeholder: BLURRED, width: 150)
                    }
                }
            }
        }
    `);

    let title = extractFirstNodeProperty(drink, 'Title');
    let coffeeTags = extractFirstNodeProperty(drink, 'CoffeeDescription').split('\n');
    let softdrinksTags = extractFirstNodeProperty(drink, 'SoftdrinksDescription').split('\n');
    let cocktailsTags = extractFirstNodeProperty(drink, 'CocktailDescription').split('\n');

    function renderDrinkTags(tags) {
        return tags.map((tag, index) => {
            return (
                <span key={`drinkTag${index}`}
                      className={`${TextModule.tag} ${TextModule.tagBlock}`}>{tag}</span>
            );
        })
    }

    let coffeeImageNode = extractSingleNodeObject(images.nodes, (node) => node.name === 'Beverage-Coffee');
    let softdrinksImageNode = extractSingleNodeObject(images.nodes, (node) => node.name === 'Beverage-Juice');
    let cocktailImageNode = extractSingleNodeObject(images.nodes, (node) => node.name === 'Beverage-Cocktail');

    let coffeeImage = getImage(coffeeImageNode.childImageSharp.gatsbyImageData);
    let softdrinksImage = getImage(softdrinksImageNode.childImageSharp.gatsbyImageData);
    let cocktailImage = getImage(cocktailImageNode.childImageSharp.gatsbyImageData);

    return (
        <ContentRow>
            <h2>{title}</h2>
            <Container>
                <Col colspan={4}>
                    <div className={DrinkModule.drinkImage}>
                        <GatsbyImage image={coffeeImage} alt="Kaffee"/>
                        {renderDrinkTags(coffeeTags)}
                    </div>
                </Col>
                <Col colspan={4}>
                    <div className={DrinkModule.drinkImage}>
                        <GatsbyImage image={softdrinksImage} alt="Getränke"/>
                        {renderDrinkTags(softdrinksTags)}
                    </div>
                </Col>
                <Col colspan={4}>
                    <div className={DrinkModule.drinkImage}>
                        <GatsbyImage image={cocktailImage} alt="Cocktails"/>
                        {renderDrinkTags(cocktailsTags)}
                    </div>
                </Col>
            </Container>
        </ContentRow>
    )
}
