import React from 'react';
import {ContentRow} from "../layout/ContentRow"
import WeatherStationOffline from "../WeatherStationOffline"
import {graphql, useStaticQuery} from "gatsby";
import {extractFirstNode} from "../../util/QueryExtractor";

export default function WeatherRow() {

    const {weather} = useStaticQuery(graphql`
            query WeatherQuery {
                weather: allStrapiWeather {
                    nodes {
                        title
                        isOffline
                        offlineText
                    }
                }
            }`
    );

    const {title, isOffline, offlineText} = extractFirstNode(weather);

    function getWeatherStationComponent() {
        if (isOffline) {
            return (
                <WeatherStationOffline offlineText={offlineText}/>
            )
        }

        return (<div></div>);
    }

    return (
        <ContentRow>
            <h2>{title}</h2>
            {getWeatherStationComponent()}
        </ContentRow>
    )
}
