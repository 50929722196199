import React from 'react'
import LocationMap from '../LocationMap'
import {ContentRow} from '../layout/ContentRow'
import * as LayoutStyle from '../../styles/layout.module.css';
import * as IconStyle from '../../styles/icon.module.css';

import CompassIcon from '../../assets/svg/compass.svg';
import {graphql, useStaticQuery} from "gatsby";
import {extractFirstNodeProperty} from "../../util/QueryExtractor";

export default function LocationInfoRow() {
    const {location} = useStaticQuery(graphql`
        query LocationQuery {
            location: allStrapiLocation {
                nodes {
                    locationSubTitle
                    locationTitle
                    locationMap {
                        position
                        zoom
                        markerPosition
                        popupText
                    }
                }
            }
        }`
    );

    const title = extractFirstNodeProperty(location, 'locationTitle');
    const subTitle = extractFirstNodeProperty(location, 'locationSubTitle');
    const locationMap = extractFirstNodeProperty(location, 'locationMap');

    return (
        <ContentRow>
            <div className={LayoutStyle.center}>
                <h2>{title} <CompassIcon className={IconStyle.icon}/></h2>
                <h3>{subTitle}</h3>
                <LocationMap locationMap={locationMap}/>
            </div>
        </ContentRow>
    );
}
