import React from 'react';
import {ContentRow} from "../layout/ContentRow"
import News from "../News"
import {graphql, useStaticQuery} from "gatsby";
import {extractFirstNodeProperty} from "../../util/QueryExtractor";

export default function NewsRow() {
    const {newsArea} = useStaticQuery(graphql`
            query NewsAreaQuery {
                newsArea: allStrapiNewsArea {
                    nodes {
                        title
                        news_links {
                            title
                            url
                        }
                        social_networks {
                            title
                            url
                        }
                    }
                }
            }
    `);

    const title = extractFirstNodeProperty(newsArea, 'title');
    const newsLinks = extractFirstNodeProperty(newsArea, 'news_links');
    const socialNetworks = extractFirstNodeProperty(newsArea, 'social_networks');

    const allLinks = socialNetworks.concat(newsLinks);

    return (
        <ContentRow>
            <h2>{title}</h2>
            <News links={allLinks}/>
        </ContentRow>
    )
}
