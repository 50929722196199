import React from "react"

import * as WeatherModule from "../styles/weather.module.css"
import * as TextModule from "../styles/text.module.css"

const colorClasses = [WeatherModule.gray, WeatherModule.yellow, WeatherModule.cyan, WeatherModule.green, WeatherModule.magenta, WeatherModule.red, WeatherModule.blue];

export default class WeatherStationOffline extends React.Component {

  renderSingleTestPictureBlock(color) {
    const className = `${WeatherModule.testColorBlock} ${color} ${WeatherModule.stripeOverlay}`;
    return (
      <span className={className} key={className}/>
    )
  }

  renderSingleSmallTestPictureBlock(color) {
    const className = `${WeatherModule.testColorBlockSmall} ${color} ${WeatherModule.stripeOverlay}`;
    return (
      <span className={className} key={className}/>
    )
  }

  renderTestPictureRow() {
    const colorBlocks = colorClasses.map(color => this.renderSingleTestPictureBlock(color));

    return (
      <div className={WeatherModule.testPictureRow}>
        {colorBlocks}
      </div>
    )
  }

  renderSmallTextPictureRow() {
    const colorBlocks = colorClasses.reverse().map(color => this.renderSingleSmallTestPictureBlock(color));

    return (
      <div className={WeatherModule.testPictureRow}>
        {colorBlocks}
      </div>
    );
  }

  render() {

    return (
      <div className={WeatherModule.testPicture}>
        <h3 className={`${WeatherModule.testText} ${TextModule.tag}`}>
          <span className={WeatherModule.glitch} data-text={this.props.offlineText}>{this.props.offlineText}</span>
        </h3>
          {this.renderTestPictureRow()}
          {this.renderSmallTextPictureRow()}
      </div>
    )
  }
}
