import React from "react"
import Helmet from "react-helmet"
import JumbotronHeader from "../components/JumbotronHeader"
import Navigation from "../components/Navigation"
import Content from "../components/layout/Content"
import LocationInfoRow from "../components/content/LocationInfoRow"
import IntroductionRow from "../components/content/IntroductionRow"
import DrinksRow from "../components/content/DrinksRow"
import Background from "../components/layout/Background"
import GalleryRow from "../components/content/GalleryRow"

import WeatherRow from "../components/content/WeatherRow"
import NewsRow from "../components/content/NewsRow"
import Footer from "../components/Footer"
import { Wave } from "../components/Wave"

import "../styles/global.css";
import 'leaflet/dist/leaflet.css';

const bgColorPrimary = "#ece6d6"
const bgColorSecondary = "#d3c7a2"
const IndexPage = () => (
  <>
    <Helmet>
      <title>Steimers Strandbar</title>
      <meta name="viewport" content="width=device-width, initial-scale=1"/>
      <meta name="description"
            content="Die Strandbar auf der Nordseeinsel Juist und der Treffpunkt für Kitesport. "/>
    </Helmet>
    <JumbotronHeader/>
    <Navigation/>
    <Content>
      <Background backgroundColor={bgColorPrimary}>
        <IntroductionRow/>
      </Background>
      <Wave backgroundColor={bgColorPrimary} waveColor={bgColorSecondary}/>
      <Background backgroundColor={bgColorSecondary}>
        <LocationInfoRow/>
      </Background>
      <Wave backgroundColor={bgColorSecondary} waveColor={bgColorPrimary} variant={1}/>
      <Background backgroundColor={bgColorPrimary}>
        <DrinksRow/>
      </Background>
      <Wave backgroundColor={bgColorPrimary} waveColor={bgColorSecondary} variant={2}/>
      <Background backgroundColor={bgColorSecondary}>
        <GalleryRow/>
      </Background>
      <Wave backgroundColor={bgColorSecondary} waveColor={bgColorPrimary} variant={3}/>
      <Background backgroundColor={bgColorPrimary}>
        <WeatherRow/>
      </Background>
      <Wave backgroundColor={bgColorPrimary} waveColor={bgColorSecondary}/>
      <Background backgroundColor={bgColorSecondary}>
        <NewsRow/>
      </Background>
    </Content>
    <Footer/>
  </>
)
export default IndexPage;
