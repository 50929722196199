
export function extractFirstNode(queryResult) {
    return queryResult.nodes[0];
}

export function extractFirstNodeProperty(queryResult, nodeName) {
    let node = extractFirstNode(queryResult);
    let content = node[nodeName];
    if (content) {
        return content;
    }

    throw Error(`Could not find ${nodeName} in first node from result`);
}

export function extractSingleNodeObject(nodes, extractFunction) {
    if (Array.isArray(nodes) && typeof extractFunction === 'function') {
        let node = nodes.find(extractFunction);
        if (node) {
            return node;
        }

        throw Error(`The given extractFunction could not match any node`);
    }

    throw Error(`The argument types have to be: (Array, Function)`);
}
