import React from "react"
import {graphql, useStaticQuery} from "gatsby";
import {getImage, GatsbyImage} from "gatsby-plugin-image";

import {convertToBgImage} from "gbimage-bridge";
import BackgroundImage from "gatsby-background-image";

import * as HeaderStyle from "../styles/header.module.css"

export default function JumbotronHeader() {
    const {jumbotronImage, strandbarLogo} = useStaticQuery(graphql`
        query HeaderQuery {
            jumbotronImage: file(relativePath: {eq: "barVorne.jpeg"}) {
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                }
            },
            strandbarLogo: file(relativePath: {eq: "steimers_logo.png"}) {
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                }
            },
        }`
    );

    const backgroundImage = convertToBgImage(getImage(jumbotronImage));
    const strandbarLogoImage = getImage(strandbarLogo)
    const headerAlt = 'Steimers Strandbar';

    return (
        <BackgroundImage
            Tag="header"
            className={HeaderStyle.heroesImage}
            {...backgroundImage}
            preserveStackingContext>
            <div className={HeaderStyle.heroesContent}>
                <div className={HeaderStyle.beachbarShape}>
                    <div className={HeaderStyle.logoContainer}>
                        <GatsbyImage image={strandbarLogoImage} alt={headerAlt}/>
                    </div>
                </div>
            </div>
        </BackgroundImage>
    )
}
